import React, { useState, useEffect } from "react";
import vi from "../Assests/Video/video.mp4";
import logo from "../Assests/Image/logo2.png";

function Hero() {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const targetDate = new Date("November 25, 2024 14:00:00").getTime(); // Set to 2 PM on November 25

    const updateCountdown = () => {
      const now = new Date().getTime();
      const difference = targetDate - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        setTimeLeft({ days, hours, minutes, seconds });
      } else {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    };

    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <div className="">
        <div className="position-relative">
          <div className="position-absolute vh-100">
            <video
              src={vi}
              className="w-100 vh-100 object-fit-cover"
              muted
              autoPlay
              loop
            />
          </div>
          <div className="overlay position-absolute vh-100 w-100"></div>
          <div className="position-absolute vh-100 w-100 d-flex flex-column align-items-center justify-content-center text-white text-center">
            <img src={logo} height={100} alt="SkillStackStudy Logo" />
            <h1 className="mt-3 anton-regular">Skill Stack Study</h1>
            <p className="fs-5">
              Stack Your Skills, Shape Your Future
            </p>
            <div className="timerContainer mt-4">
              <p className="display-1 fw-bolder">
                {timeLeft.days}d : {timeLeft.hours}h : {timeLeft.minutes}m :{" "}
                {timeLeft.seconds}s
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
